/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogRelatedProducts.BlogRelatedProducts_isOverridden {
    .ProductLinks {
        padding: 32px;

        &-Title {
            margin: 0;
            padding: 0;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
        }
    }

    .BlogRelatedProducts {
        &-Wrapper {
            padding: 0;

            .ProductCard-Name {
                margin: 15px 0 0;
                padding: 0;
                font-size: 13px;
                font-weight: 350;
                line-height: 20px;
                text-align: center;
            }

            .slick {
                &-arrow {
                    position: absolute;
                    top: 50%;
                    z-index: 1;
                }

                &-prev {
                    left: -26px;
                }

                &-next {
                    right: -26px;
                }
            }
        }
    }
}
