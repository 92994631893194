/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductActions {
    display: flex;
    flex-direction: column;

    @include mobile {
        display: grid;
        padding: 0;
    }

    .MarketingClaims {
        order: 4;
    }

    &-PriceWrapper {
        display: none !important;
    }

    &-Schema {
        display: none !important;
    }

    &-ProductLinks {
        display: flex;
        flex-wrap: wrap;

        a {
            margin-right: 16px;
            margin-bottom: 16px;
            font-family: $font-filson-soft;
            font-size: 15px;
            font-weight: 400;
            text-align: center;
        }

        &Wrapper {
            margin-bottom: 16px;

            span {
                display: block;
                font-size: 15px;
                font-weight: 350;
                margin-bottom: 8px;
            }
        }
    }

    &-Title {
        margin-top: 0;
        margin-bottom: 25px;
        line-height: 45px;

        @include mobile {
            font-size: 26px;
            line-height: 36px;
        }

        @include desktop {
            line-height: 45px;
        }
    }

    &-Section {
        font-size: 15px;
        font-weight: 350;

        .TypographyParagraph {
            color: $black;
        }

        .CMSList {
            &-Item {
                padding-bottom: 0;

                &:not(:last-child) {
                    padding-bottom: 8px;
                }
            }

            &-ItemTick {
                display: block;
                padding: 0 16px 0 0;
                margin-top: 4px;
                margin-right: 16px;
                height: 16px;
                width: 16px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-image: url("../../assets/svg/product-tick.svg");
            }
        }

        .OfferCMS-ContentWrapper-Btn {
            padding: 8px 20px !important;
            margin: 0 16px 16px 0 !important;
        }

        &_type {
            &_short {
                margin-bottom: 33px;
                width: 100%;

                @include mobile {
                    border-bottom: 0;
                }
            }

            &_name {
                @include mobile {
                    display: block;
                }
            }
        }
    }

    &-AddToCartWrapper {
        > button {
            padding: 21px 0;
        }
    }

    &-AttributesWrapper {
        margin-bottom: 40px;

        @include tablet {
            margin-bottom: 32px;
        }

        .ProductConfigurableAttributes {
            & > div {
                &:not(:first-child) {
                    margin-top: 24px;
                }
            }

            &-Title {
                margin: 0 0 8px;
                font-size: 13px;
                line-height: 20px;
                font-weight: 350;
                color: $neutral70;
            }

            &-SwatchList {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                grid-template-columns: repeat(auto-fill, minmax(0, auto));
                margin: 0;
                padding: 0;
            }

            .ProductAttributeValue-String {
                border: 0;
                background-color: $white;
                box-shadow: 0 10px 15px 5px rgba(158, 197, 223, 0.15);
                margin: 0;
                border-radius: 100px;
                font-size: 13px;
                line-height: 32px;

                &:hover,
                &_isSelected {
                    color: $white;
                    background-color: var(--option-text-color);
                }
            }
        }
    }

    .ProductAttributes {
        position: relative;
        padding-left: 22px;
        margin-bottom: 40px;

        @include tablet {
            margin-bottom: 32px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            border-radius: 15px;
            background-color: $primary50;
        }

        &-Attribute {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 15px;
            font-weight: 350;
            line-height: 23px;
            margin: 0;

            &:not(:first-child) {
                margin-top: 4px;
            }
        }

        &-AttributeLabel {
            margin-right: 16px;
            color: $neutral70;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }

        &-AttributeValue {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: $black;
        }
    }
}


