@mixin submenuWrapper {
    position: absolute;
    top: 38px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $neutral5;
    border-radius: 15px;
    width: auto;
    z-index: 10;
    box-shadow: 0px 40px 80px 0px #5295C82E;
}

@mixin headerHeroImage {
    position: absolute;
    bottom: 0;
    left: 44px;
    margin-right: 44px;
    margin-bottom: 0;
    padding: 40px 40px 0 40px;
    background-color: #fff;

    @include tablet {
        left: 24px;
        margin-right: 24px;
        padding: 20px 25px 0 25px;
    }

    @include mobile {
        position: static;
        padding: 20px 24px;
        background-color: #f3f8fb;
        margin-right: 0;
    }
}

@mixin aspect-ratio($value, $element: "& > *") {
    &::after {
        content: "";
        display: block;
        padding-bottom: $value;
    }

    #{$element} {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
    }
}

@mixin container() {
    max-width: var(--content-wrapper-width);
    padding-inline: 32px;
    margin-inline: auto;

    @include mobile {
        padding-inline: 14px;
    }
}

@mixin scrollbar($scrollbar-thumb-color: #c4c4c4, $scrollbar-rail-color: #ebebeb) {
    $scrollbar-width: 6px;
    $scrollbar-width-moz: thin;
    $scrollbar-border-radius: 10px;

    // Chrome & Safari (WebKit)

    &::-webkit-scrollbar {
        background: $scrollbar-rail-color;
        width: $scrollbar-width;
        height: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-rail-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border-radius: $scrollbar-border-radius;
    }

    // Firefox (Gecko)
    scrollbar-color: $scrollbar-thumb-color $scrollbar-rail-color;
    scrollbar-width: $scrollbar-width-moz;
}

@mixin line-clamp($font-size: 1em, $line-height: 1.4, $lines-to-show: 0) {
    display: block;
    display: -webkit-box;
    height: $font-size * $line-height * $lines-to-show;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
