/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductDescription {
    @include container;

    margin-block: 100px;

    @include tablet {
        margin-block: 60px;
    }

    @include mobile {
        padding: 0;
    }

    &-ContentWrapper {
        display: grid;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        img {
            margin-left: 80px;
        }
    }

    .ShowMore {
        .Button {
            font-family: $font-filson-soft;

            @include tablet {
                width: 100%;
            }
        }
    }

    &-TickWrapper {
        display: grid;
        margin-top: 60px;
        padding-top: 60px;
        border-top: solid 1px $primary10;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 100px;
            padding-top: 100px;
        }

        &-Img {
            margin-left: auto;
            margin-right: 80px;
            max-width: 315px;
        }

        &-Content {
            padding-right: 20px;
        }
    }

    &-Title {
        max-width: 358px;
        letter-spacing: normal;
    }

    &-Image {
        img {
            width: fit-content;
        }
    }

    .CMSList {
        &-Item {
            padding-bottom: 21px;
        }

        &-ItemTick {
            display: block;
            padding: 0 16px 0 0;
            margin-top: 4px;
            margin-right: 16px;
            height: 16px;
            width: 16px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-image: url("../../assets/svg/product-tick.svg");
        }

        &-ItemContent {
            font-size: 15px;
        }
    }
}

@include tablet {
    .ProductDescription {
        &-Image {
            display: none;
        }

        &-TickWrapper {
            display: flex;

            &-Img {
                display: none;
            }

            &-Content {
                width: 100%;
            }

            .ProductDescription-Title {
                max-width: initial;
            }

            .CMSList {
                max-width: initial;
            }
        }
    }
}
