/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewForm {
    &-Wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }

    &-ProductName {
        font-weight: 700;
        font-size: 24px;

        @include mobile {
            font-size: 25px;
        }
    }

    &-Rating {
        height: 24px;
        text-align: center;
        margin-inline: 0;
        direction: rtl;
        border: 0;
        font-size: 24px;
        /* stylelint-disable-next-line unknownProperties, property-no-unknown */
        white-space-collapse: discard; // alternative of `font-size: 0`
    }

    &-Legend {
        border-block-end: 0;
        padding-block-end: 4px;
        font-weight: 700;
        font-size: 13px;

        @include mobile {
            padding-block-end: 4px;
            font-size: 15px;
        }
    }

    &-Summary {
        position: relative;
    }

    &-Tooltip {
        position: absolute;
        top: 50%;
        right: 21px;
        transform: translateY(-50%);

        &-Presentation {
            padding: 16px !important;
            border-radius: 10px !important;
            box-shadow: 0px 3px 50px 5px #9EC5DF4D;
            width: 180px !important;
            font-size: 13px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
        }
    }

    .Field {
        margin-top: 24px;

        &:first-of-type {
            margin-top: 0;
        }

        &-Label {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
        }

        & input, & textarea {
            height: 48px;
            padding: 10px 21px;
            border-radius: 10px;
            border-color: $primary20;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;

            &#title {
                padding-right: 58px;
            }
        }

        & textarea {
            padding: 18px 21px 10px;
            height: 176px;
        }
    }

    &-Content {
        input,
        textarea {
            width: 100%;
        }
    }

    & &-Button {
        margin-block-start: 40px;
        width: 100%;
        height: 56px;
        padding-top: 0;
        padding-bottom: 0;
        padding-inline: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;

        @include mobile {
            width: 100%;
        }
    }
}
