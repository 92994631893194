/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-review-item-border: #d8d8d8;
}

.ProductReviewItem {
    border-radius: 25px;
    padding: 24px;
    box-shadow: -2px 10px 15px 8px rgba(158, 197, 223, 0.2);
    margin: 0;
    word-break: break-word;

    &:last-child {
        margin-block-end: 0;
    }

    &::before {
        content: none;
    }

    &-ReviewTitle {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        margin-block-end: 12px;
    }

    &-Content {
        grid-area: content;
    }

    &-RatingSummary {
        line-height: 1;
        margin-bottom: 19px;

        @include mobile {
            width: 100%;
        }
    }

    &-RatingSummaryItem {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-block-end: 16px;

        @include mobile {
            margin-block-end: 7px;
        }

        &:last-of-type {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            --star-size: 20px;

            .StarIcon {
                fill: #F7A912;
            }

            margin-block: 0 2px;
            margin-inline: 0;

            @include mobile {
                margin-block: 0 3px;
                margin-inline: 0;
            }
        }
    }

    &-ReviewContent {
        font-size: 13px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;

        .TextPlaceholder {
            display: block;
        }
    }

    &-ReviewAuthor {
        margin-block: 0 19px;
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
        text-align: left;

        span {
            margin-left: 18px;
            color: $neutral50;
            font-size: 13px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;

        }
    }

    &-ReviewDetails {
        margin-block-end: 0;
        text-align: justify;

        @include mobile {
            margin-block-end: 14px;
        }

        .TextPlaceholder {
            margin-block-start: 6px;
            line-height: 1.1;

            @include mobile {
                margin-block-start: 7px;
            }

            &::after {
                display: block;
            }
        }
    }

    .ProductReviewRating-Counter {
        display: none;
    }

    &-RatingItem {
        margin-block: auto;
    }
}
