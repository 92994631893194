/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductGallery {
    margin: 0 0 36px;
    height: auto;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr)) repeat(1, minmax(0, 100px));

    &-ProductCollection {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 8px 4px;
        font-family: filson-soft;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        color: #FFFFFF;
        right: 0;
        top: 0;
        position: absolute;
        background: #9EC5DF;
        z-index: 10;

        &_color_nature {
            background: #6BC29C;
        }

        &_color_advanced {
            background: #9196CA;
        }

        &_color_marine {
            background: #4F7BBD;
        }
    }

    &-SliderWrapper {
        &:not(&_isImageZoomPopupActive) {
            &::before {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }

        .Slider {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
        }
    }

    &-SliderImage {
        &Wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        width: auto;

        .Image-Image {
            transform: none;
            position: static;
        }
    }

    &-Additional {
        height: 100%;
        margin: 0;

        @include mobile {
            display: block;
        }

        &Wrapper {
            justify-self: center;
            height: 100%;
            width: 100%;
            max-width: 600px;
        }

        .slick {
            &-slider {
                &, [class^="slick-"] {
                    height: 100%;
                }
            }

            &-slide {
                border: 0;
                padding-bottom: 0;
                width: 100px;

                .ProductGalleryThumbnailImage {
                    position: static;
                    height: 100%;
                    padding: 0;
                }

                &:not(.slick-current) {
                    filter: opacity(.5);
                }

                & div {
                    height: 100%;
                }
            }
        }
    }
}
