:root {
    --button-color: #fff;
    --button-bg: #9EC5DF;
    --button-border-color: var(--button-bg);
}

@mixin button-disabled {
    opacity: 100%;
    color: var(--button-color);
    background: var(--button-bg);
    cursor: not-allowed;
}

@mixin button {
    font-family: "filson-pro",sans-serif;
    text-decoration: none;
    display: inline-block;
    border: 2px solid var(--button-border-color);
    border-radius: 100px;
    font-weight: 400;
    font-size: 15px;
    padding: 19px 48px 18px;
    cursor: pointer;
    box-sizing: border-box;
    background: var(--button-bg);
    color: var(--button-color);
    transition: $defaultTransition;
    &:not([disabled]) {
        &:hover,
        &:focus {
            @include after-mobile {
                --button-bg: #70A3CA;
                --button-border-color: var(--button-bg);

                border-color: var(--button-border-color);
                background-color: var(--button-bg);
                color: var(--button-color);
            }
        }
    }
    &:hover {
        background: var(--button-bg);
        text-decoration: none;
    }
    &[disabled] {
        @include button-disabled;
    }
}

.Button {
    @include button();

    &_priority_primary {
        --button-bg: #9EC5DF;
        --button-border-color: var(--button-bg);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #70A3CA;
                }
            }
        }
        &[disabled] {
            --button-bg: #EDF0F3;
            --button-color: #AAAFB6;
        }
    }
    &_priority_secondary, &_isHollow {
        --button-bg: #fff;
        --button-color: #70A3CA;
        --button-border-color: var(--button-color);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #70A3CA;
                    --button-color: #fff;
                    --button-border-color: var(--button-bg);
                }
            }
        }
        &[disabled] {
            --button-bg: #fff;
            --button-color: #AAAFB6;
            --button-border-color: var(--button-color);
        }
    }
    &_priority_other {
        --button-bg: #fff;
        --button-color: #70A3CA;
        --button-border-color: var(--button-bg);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #ADCEE4;
                    --button-color: #fff;
                    --button-border-color: var(--button-bg);
                }
            }
        }
        &[disabled] {
            --button-bg: transparent;
            --button-color: #EDF0F3;
            --button-border-color: var(--button-color);
        }
    }

    &_size_small {
        padding: 15px 30px;
    }

    &_likeLink {
        --button-border: transparent;
        --button-bg: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        text-align: start;

        &:hover,
        &:focus {
            text-decoration: underline;
            padding: 0;
        }
    }
}
