/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductLinks {
    &-Title {
        margin-top: 0;
        padding-top: 0;
        text-transform: initial;
        font-family: $font-filson-soft;
        text-align: center;

        @include desktop {
            font-size: 42px;
        }

        @include tablet {
            font-size: 32px;
        }

        @include mobile {
            font-size: 27px;
        }
    }

    &-List {
        display: block;
    }

    &-Slider {
        &.SliderWidget {
            .slick-slide {
                padding: 0 24px;
            }

            .slick-arrow {
                --arrow-height: 41px;
                --arrow-width: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: $primary50;
                width: 41px;
                height: 41px;
                border-radius: 50%;
                top: 25%;

                svg {
                    fill: #ffffff;
                }

                &_isNext {
                    right: 0;
                }

                &_isPrev {
                    left: 0;
                }
            }
        }
    }

    &_forBlog {
        .ProductCard {
            &-Link {
                @include mobile {
                    display: block;
                    max-width: 194px;
                    margin: 0 auto;
                }
            }
        }
    }
}
