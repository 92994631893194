/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 810px)
    and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px)
    and (max-width: 1280px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin ultra-wide-desktop {
    @media (min-width: 1921px) {
        @content;
    }
}

// should be removed in future releases
// kept as legacy support
@mixin before-desktop {
    @media (max-width: $desktop - 1) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $desktop - 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media(min-width: $tablet)
        and (max-width: $desktop - 1)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media(min-width: $tablet)
        and (max-width: $desktop - 1)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: landscape) {
        @content;
    }
}

// should be removed in future releases
// kept as legacy support
@mixin after-mobile {
    @include desktop {
        @content;
    }
}

@mixin before-mobile-legacy {
    @media (min-width: $desktop) {
        @content;
    }
}

// should be removed in future releases
// kept as legacy support
@mixin before-mobile {
    @media(min-width: $tablet){
        @content;
    }
}

@mixin mobile {
    @media (max-width: $tablet - 1) {
        @content;
    }
}

@mixin mobile-xs {
    @media (max-width: $mobile - 1) {
        @content;
    }
}
@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}

@mixin mobileAndTablet {
    @media (max-width: $mobile - 1) and (max-width: $desktop - 1)  {
        @content;
    }
}
