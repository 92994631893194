/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviews {
    @include container;

    border-bottom: 1px solid $primary10;
    padding-block: 100px;

    @include mobile {
        padding-block: 60px;
        padding-inline: 0;
    }

    .Button {
        font-family: $font-filson-soft;
    }

    &-Header {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: flex-start;
        align-items: center;
        grid-gap: 28px;
        margin-bottom: 49px;

        @include mobile {
            display: grid;
            grid-template-columns: 1fr;
            justify-items: flex-start;
            grid-gap: 16px;
            margin-bottom: 32px;
        }

        .ProductReviewRating {
            margin-right: 16px;
        }

        .ProductReviews-Button {
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
            padding-inline: 32px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
        }

        .StarIcon {
            height: 24px;
            width: 24px;
            fill: #F7A912;
        }

        .TypographyHeader {
            margin: 0;
        }
    }

    &-Summary {
        align-items: center;
        display: flex;
    }

    &-SummaryRating {
        --star-size: 27px;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    &-Popup {
        --popup-background: #{rgba($primary10, 0.8)};

        &.Popup_isVisible {
            top: 0;
            height: 100%;
        }

        .Popup-Header {
            @include mobile {
                display: block;
            }
        }

        .Popup-Heading {
            font-family: $font-filson-soft;
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            line-height: 45px;
            margin-bottom: 16px;
            text-transform: none;
        }

        .Popup-Content {
            padding: 40px;
            border-radius: 15px;
            box-shadow: 0px 40px 80px 0px #5295C82E;
            max-height: 100%;
            overflow-x: hidden;

            @include scrollbar();

            @include mobile {
                border-radius: 0;
            }
        }

        .Popup-CloseBtn {
            top: -30px !important;
            left: initial !important;
            right: -30px !important;

            @include mobile {
                top: -30px !important;
                left: initial !important;
                right: -30px !important;
            }
        }
    }

    &-SummaryDetails {
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        line-height: 23px;
        text-align: left;

        span {
            &::before {
                content: ' / ';
            }
        }
    }
}
