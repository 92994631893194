/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

.ProductCard {
    height: fit-content;
    padding-inline-start: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;

    &:hover {
        @include desktop {
            .ProductCard-Name {
                color: $primary90;
            }
        }

        .ViewButton {
            --button-bg: #{$primary90};
        }
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 7px;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    &-Content {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;

        &_layout_list {
            justify-content: space-between;
            margin-inline-start: 20px;
            max-width: 325px;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        font-family: 'filson-pro', sans-serif;
        font-size: 18px;
        color: $neutral90;
        font-weight: 400;
        margin-bottom: 12px;
        margin-top: 5px;
        line-height: var(--product-card-name-line-height);
        padding-block-end: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: color .3s ease-in-out;

        @include desktop {
            line-height: 1.3;
        }
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 4px;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 12px;
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-block-end: 100%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-FigureReview {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;
        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;

        @include mobile {
            --product-rating-size: 14px;

            width: 100%;
            border-radius: 0;
            height: 18px;
        }

        @include desktop {
            height: 25px;
        }
    }

    &-Label {
        position: absolute;
        top: -170px;
        background-color: #fff;
    }

    &-LabelValue {
        font-family: 'filson-soft', sans-serif;
        color: $neutral90;
        font-size: 16px;
        font-weight: 700;
        padding: 8px;
        line-height: 1;
        letter-spacing: 0.05em;
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-Price {
        width: 100%;

        @include desktop {
            font-size: 24px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 12px;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    &-ProductActions {
        display: flex;
        margin-inline-start: 7px;

        .ProductWishlistButton {
            margin-inline-end: 10px;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-block-start: 5px;

        > button {
            padding: 21px 0;
        }

        > * {
            margin-inline-end: 5px;
        }
    }

    &-VisibleOnHover {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 10px;
        padding-inline: 15px;
        opacity: 0;
        position: absolute;
        inset-block-start: 100%;
        display: none;
    }

    &-FlavourContainer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        > div {
            margin: 0 5px 5px 0;
        }
    }

    &-FlavourChip {
        font-weight: 400;
        padding: 0 12px;
        border-radius: 16px;

        &_variant_194 {
            // apple
            background-color: rgb(232, 17, 45);
        }

        &_variant_195 {
            // orange
            background-color: rgb(255, 194, 99);
        }

        &_variant_196 {
            // raspberry
            background-color: rgb(255, 181, 190);
        }

        &_variant_197 {
            // blueberry
            background-color: rgb(109, 40, 170);
        }

        &_variant_180 {
            // lemon
            background-color: rgb(251, 242, 127);
        }

        &_variant_167 {
            // cherry
            background-color: rgb(235, 187, 202);
        }

        &_variant_198 {
            // peppermint
            background-color: rgb(184, 237, 225);
        }

        &_variant_168 {
            // blackcurrant
            background-color: rgb(212, 198, 217);
        }

        &_variant_199 {
            // unflavoured
            background-color: rgb(227, 244, 255);
        }

        &_variant_190 {
            // wild berry
            background-color: rgb(211, 115, 138);
        }

        &_variant_191 {
            // salted caramel
            background-color: rgb(231, 171, 132);
        }

        &_variant_201 {
            // grapefruit
            background-color: rgb(239, 148, 138);
        }

        &_variant_202 {
            // lemon mint
            background-color: rgb(236, 243, 154);
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }
}

@include narrow-desktop {
    .ProductCard {
        &-Label {
            top: -16vw;
        }
    }
}

@include tablet {
    .ProductCard {
        &-Label {
            top: 0;
            transform: translateY(-100%);
        }
    }
}

@include mobile {
    .ProductCard {
        &-Label {
            top: 0;
            transform: translateY(-100%);
        }
    }
}
