/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage {
    @include tablet {
        padding-bottom: 0;
    }

    .ProductLinks {
        margin: 0;

        &-Wrapper {
            border-bottom: 1px solid $primary10;
            padding-block: 100px;

            @include tablet {
                padding-block: 60px;
                border-top: 1px solid $primary10;
                border-bottom: none;
            }
        }
    }

    &-Wrapper {
        padding-top: 40px;
        grid-template-columns: 50% 50%;

        @include after-mobile {
            grid-column-gap: 25px;
        }

        @include tablet {
            padding-bottom: 60px;
        }

        @include mobile {
            grid-template-columns: 100%;
            padding-top: 0;
        }
    }

    .ProductOpinion {
        @include tablet {
            margin-left: -16px;
            margin-right: -16px;
            padding-bottom: 60px;

            &-Header {
                padding: 0 16px;
            }

            .SliderWidget {
                padding: 0;

                &-OpinionWrapper {
                    padding: 0 16px;
                }

                .Slider-Arrow {
                    top: 64px;
                    transform: translateY(50%);

                    &_isPrev {
                        left: 16px;
                    }

                    &_isNext {
                        right: 16px;
                    }
                }
            }
        }
    }
}
