/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --option-check-mark-background: #{$black};
    --option-text-color: #{$neutral70};
}

.ProductAttributeValue {
    &-String,
    &-Text {
        font-family: 'filson-pro', sans-serif;

        &_isSelected {
            color: $primary90;
        }

        span {
            font-size: 15px;
        }
    }

    &-Text {
        label {
            &:first-of-type {
                .input-control {
                    border: solid 1px $primary90;
                    background-color: #fff;
                    @include desktop {
                        margin-block-start: 3px;
                    }
                }
            }
        }

        @include desktop {
            padding-block-end: 23px;
        }
    }
}
