/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductLabel {
    @include container;

    @include mobile {
        padding: 0;
    }

    & + .GroupOfAttributes {
        margin-top: 100px;

        @include tablet {
            margin-top: 60px;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-evenly;
        background-color: $primary5;
        border-radius: 16px;

        @include desktop {
            grid-template-columns: 1fr 2px 1fr;
            border-radius: 30px;
        }
    }

    &-Separator {
        height: 2px;
        width: 100%;
        background-color: $white;

        @include desktop {
            height: 100%;
            width: 2px;
        }
    }

    &-Table {
        padding: 32px 20px;
        width: 100%;

        @include desktop {
            padding: 48px 70px;
        }

        .TypographyHeader_heading_h4 {
            margin-bottom: 16px;
            letter-spacing: normal;

            @include desktop {
                margin-bottom: 24px;
            }
        }

        .TypographyHeader_heading_h6 {
            margin-bottom: 0;
            border-bottom: 1px solid $primary10;
            padding: 8px 0;
            letter-spacing: normal;
        }

        &-Headings, &-Content {
            display: flex;

            p:nth-of-type(1),
            h6:nth-of-type(1) {
                width: 46%;
            }

            p:nth-of-type(2),
            p:nth-of-type(3),
            h6:nth-of-type(2),
            h6:nth-of-type(3) {
                width: 27%;
                display: flex;
            }

            p:nth-of-type(2),
            h6:nth-of-type(2) {
                margin-right: auto;
            }

            p:nth-of-type(1),
            h6:nth-of-type(1),
            p:nth-of-type(2),
            h6:nth-of-type(2) {
                padding-right: 15px;
            }
        }

        &-Content {
            flex-direction: column;

            &-Row {
                display: flex;
                padding: 8px 0;

                &:not(:last-child) {
                    border-bottom: 1px solid $primary10;
                }

                p {
                    color: $neutral90;
                }
            }
        }

        .TypographyHeader_heading_h6,
        .TypographyParagraph_size_small {
            font-size: 13px;
        }
    }
}

@include mobile {
    .ProductLabel {
        &-Table {
            width: 100%;

            .TypographyParagraph_size_small {
                font-size: 12px;
            }

            &-Headings, &-Content {
                p:nth-of-type(1),
                h6:nth-of-type(1) {
                    width: 38%;
                }

                p:nth-of-type(2),
                p:nth-of-type(3),
                h6:nth-of-type(2),
                h6:nth-of-type(3) {
                    width: 31%;
                }
            }
        }
    }
}
