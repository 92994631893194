/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #0a0a0a;
$orange: #F1AB52;
$default-primary-base-color: #70A3CA;
$default-primary-dark-color: #0E0F11;
$default-primary-light-color: #f69e77;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #929292;
$default-secondary-light-color: #f8f8f8;
$default-blue: #70A3CA;
$font-muli: 'Muli', sans-serif;
$font-filson-pro: "filson-pro", sans-serif;
$font-filson-soft: "filson-soft", sans-serif;
$font-standard-size: 62.5%;
$defaultTransition: all .3s ease-in-out;
$desktop: 1024px;
$tablet: 768px;
$mobile: 425px;

$gray1: #c8c8c8;
$gray2: #bababa;
$gray3: #535f6c;
$gray4: #d4d4d4;

$blue1: #9DC5DF;

/* colors pallet from design */

// neutral
$neutral5: #FFFFFF;
$neutral10: #F9FAFB;
$neutral20: #EDF0F3;
$neutral30: #E0E3E6;
$neutral40: #D0D4D9;
$neutral50: #AAAFB6;
$neutral60: #838D95;
$neutral70: #5E6875;
$neutral80: #3C404B;
$neutral90: #0E0F11;

// nature
$nature20: #EDF0F3;
$nature25: #EFF7F2;
$nature50: #71B790;
$nature70: #528463;
$nature: #76C09A;
$light_nature: #C3E1CE;

// vital
$vital70: #75a8c9;
$vital: #9DC5DF;
$vital_secondary: #F5F5F5;

// marine
$marine: #435DA4;
$marine25: #ECEFF9;
$marine70: #304377;
$marine90: #485574;
$marine_secondary: #4F7BBD;


// advanced
$advanced5: #E6E6F4;
$advanced10: #DDDDF1;
$advanced20: #D2D2EC;
$advanced25: #F3F3FA;
$advanced30: #C3C3E6;
$advanced40: #B0B0DD;
$advanced50: #9595D2;
$advanced60: #777DBA;
$advanced70: #5D69A6;
$advanced80: #515F8B;
$advanced90: #485574;
$advanced: rgb(145, 150, 202);

// eco

//error
$error5: #FFEAEA;
$error10: #FFE3E3;
$error20: #FFDADA;
$error30: #FFCECE;
$error40: #FFBEBE;
$error50: #FBA1A1;
$error60: #F07777;
$error70: #E24F4F;
$error80: #D33131;
$error90: #AF2E2E;

//primary
$primary5: #F3F8FB;
$primary10: #E2EEF5;
$primary20: #CFE2EF;
$primary30: #BBD6E9;
$primary40: #ADCEE4;
$primary50: #9EC5DF;
$primary60: #96BFDB;
$primary70: #8CB8D7;
$primary80: #82B0D2;
$primary90: #70A3CA;
