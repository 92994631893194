/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewList {
    margin-block-start: 24px;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(4, 1fr);

    @include tablet {
        margin-block-start: 28px;
        grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
        grid-template-columns: 1fr;
    }

    &_isSlider {
        display: block;
        margin-inline: -16px;
    }

    &-Slider {
        margin-top: -24px;

        &.SliderWidget {
            .slick-slide {
                @include tablet {
                    padding: 24px 16px;
                }
            }

            .slick-track {
                display: flex;

                .slick-slide {
                    height: auto;
                    display: flex;

                    & > div {
                        display: flex;
                        height: auto;
                        width: 100%;

                        & > * {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &-Title {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-block-start: 36px;

        @include mobile {
            font-size: 28px;
            margin-block-start: 42px;
        }
    }

    &-ShowMoreButton.Button {
        display: block;
        margin: 40px auto 0;
        border-width: 1px;
        padding: 12px 30px;
    }
}
