/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAttributes {
    position: static;

    &-Attribute {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 15px;
        font-family: 'filson-pro', sans-serif;
        color: $neutral90;
        font-weight: 400;
        margin-bottom: 15px;

        &_isProductCard {
            font-size: 13px;
            color: $neutral70;
            margin-bottom: 8px;
        }
    }

    &-AttributeLabel {
        margin-right: 8px;
        color: $neutral50;
        font-size: 15px;

        &_isProductCard {
            font-size: 13px;
        }
    }
}
