/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';


.ProductListPage {
    display: grid;

    &:not(&_isWidget) {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: max-content;
        grid-column-gap: 12px;

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            grid-column-gap: 14px;
            grid-template-columns: 1fr 1fr;
            border: 0;
            padding-inline: 10px;
        }

        @include desktop {
            grid-column: 2;
        }
    }

    &-Slider {
        &.AdvoxSlider {
            .slick-slide {
                padding: 0 24px;
            }

            .slick-arrow {
                --arrow-height: 41px;
                --arrow-width: 18px;
                position: absolute;
                display: flex !important;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: $primary50;
                width: 41px;
                height: 41px;
                border-radius: 50%;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;

                svg {
                    fill: #ffffff;
                }

                &.slick-next {
                    right: 0;
                }

                &.slick-prev {
                    left: 0;
                }
            }
        }
    }

    &_isWidget {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &-Offset {
        @include mobile {
            height: 150vh;
            inset-block-start: -150vh;
        }

        height: 100vh;
        pointer-events: none;
        inset-block-start: -100vh;
        position: absolute;
        inset-inline-start: 0;
        width: 100%;

        &::before {
            display: none;
        }
    }
}
