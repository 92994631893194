/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductIcons {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(55px, auto));
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
        margin-bottom: 40px;
    }

    &_isBigSquare {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-Icon {
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 0 10px 15px 5px rgba(158, 197, 223, 0.2);
        padding: 6px;

        &-Tooltip {
            color: #9ea3a9;
            font-size: 13px;
            background: $primary5;
            font-family: filson-soft;
        }

        &_isBigSquare {
            padding: 0;
            height: 73px;
            width: auto;
            box-shadow: none;
            border-radius: unset;

            img {
                max-height: 73px;
                width: auto;
                max-width: 100%;
            }
        }
    }
}
